import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import PrimeVue from 'primevue/config';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
Vue.config.productionTip = false
Vue.component('Dropdown', Dropdown);
Vue.component('MultiSelect', MultiSelect);
import 'primevue/resources/themes/saga-blue/theme.css'      
import 'primevue/resources/primevue.min.css'           
import 'primeicons/primeicons.css'
new Vue({
  router,
  store,
  vuetify,
  PrimeVue,
  render: h => h(App)
}).$mount('#app')
