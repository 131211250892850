<template>
  <v-app>
    <div class="container">
      <template>
        <v-row class="fill-height">
          <v-col>
            <v-sheet height="64">
              <v-toolbar flat>
                <v-btn fab text small color="grey darken-2" @click="prev">
                  <v-icon small> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="next">
                  <v-icon small> mdi-chevron-right </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  class="mr-4"
                  color="grey darken-2"
                  @click="type = 'month'"
                >
                  Mes
                </v-btn>
              </v-toolbar>
            </v-sheet>
            <v-sheet height="600">
              <v-calendar
                ref="calendar"
                v-model="focus"
                color="primary"
                :events="events"
                :event-color="getEventColor"
                :type="type"
                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="viewDay"
                @click:interval="prueba3"
                @click:time="IniciarAgenda"
                @change="updateRange"
                locale="es"
                event-overlap-mode="column"
              ></v-calendar>
              <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-x
              >
                <v-card color="grey lighten-4" min-width="350px" flat>
                  <v-toolbar :color="selectedEvent.color" dark>
                    
                    <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn @click="borrar(selectedEvent)" icon :disabled="estadoDisabled">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    
                  </v-toolbar>
                  <v-card-text>
                    <span v-html="selectedEvent.details"></span>
                    <v-select
                      item-color="blue"
                      v-model="estadoSelected"
                      :items="estados"
                      label="Estados"
                      @change="prueba4" 
                      :disabled="estadoDisabled"
                      
                    ></v-select>
                  </v-card-text>
                        
                  <v-card-actions>
                    <v-btn style="color:white;"  color="red accent-4" @click="selectedOpen = false">
                      Cancelar
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn  :disabled="estadoDisabled" style="color:white;" color="green lighten-2" @click="ActualizarEstado(selectedEvent)">
                      Actualizar estado
                    </v-btn>
                    
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-sheet>
          </v-col>
        </v-row>
      </template>
    </div>
    <template>
        <v-form lazy-validation ref="form" v-model="valid">
          <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="100%">
              <v-card>
                <v-card-title>
                  <span class="text-h5">Nuevo Evento</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          item-color="blue"
                          
                          :items="items"
                          v-model="itemsSelected"
                          
                          label="Empresa"
                          :rules="rules.select2"
                          multiple
                          @change="SeleccionarEmpresa"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="8">
                        <v-text-field :rules="[v => !!v || 'Escribe un titulo']" v-model="titulo" required label="Titulo"></v-text-field
                      ></v-col>

                      <v-col cols="12" sm="6" md="6">
                        <v-menu
                          v-model="menu3"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date"
                              label="Fecha A Mostrar"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date"
                            @input="menu3 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          item-color="red"
                          @change="cambioJornada()"
                          :items="jornada"
                          label="Jornada"
                          v-model="jornadaSelected"
                        ></v-select
                      ></v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-dialog
                          ref="dialog"
                          v-model="modal2"
                          :return-value.sync="time3"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="time"
                              label="Hora Inicio Agenda"
                              prepend-icon="mdi-clock-time-four-outline"
                              :disabled="switch2"
                              v-bind="attrs"
                              v-on="on"

                            ></v-text-field>
                          </template>
                          <v-time-picker
                            format="ampm"
                            v-if="modal2"
                            v-model="time2"
                            full-width
                          >
                            <v-spacer></v-spacer>
                            <v-btn style="color:white;"  color="red accent-4" @click="modal2 = false">
                              Cancelar
                            </v-btn>
                            <v-btn  color="primary" @click="saveHora">
                              OK
                            </v-btn>
                          </v-time-picker>
                        </v-dialog></v-col
                      >
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          item-color="red"

                          :items="duracion"
                          label="Duración Evento"
                          v-model="duracionSelected"
                          :disabled="switch2"
                        ></v-select>
                      </v-col>
                      <v-col v-if="(rutinaSelected == 2)" cols="12" sm="6" md="6">
                        <MultiSelect   :filter="true" v-model="diaSelected" @change="prueba4"   :options="dia" optionLabel="text" placeholder="Dias Repetición" />
                      </v-col>
                      <v-col cols="2" sm="6" md="6">
                        <v-switch
                          v-model="switch2"
                          inset
                          label="Evento De Todo El Día"
                        ></v-switch>
                      </v-col>
                      <v-col cols="2" sm="6" md="6">
                        <v-select
                          item-color="red"
                          @change="prueba4"
                          :items="actividad"
                          label="Tipo De Actividad"
                          v-model="actividadSelected"
                          
                        ></v-select>
                      </v-col>
                      
                      <v-col cols="12" sm="6" md="12">
                        <template>
                          <v-container fluid>
                            <v-textarea
                              clearable
                              clear-icon="mdi-close-circle"
                              label="Descripción"
                              value=""
                              v-model="descripcion"
                              
                            ></v-textarea>
                          </v-container>
                        </template>
                      </v-col>
                      <v-col cols="12" sm="6" md="8">
                        <label>Urgencia</label>
                        <v-radio-group  v-model="radio" row>
                          <v-radio label="1" value="1"></v-radio>
                          <v-radio label="2" value="2"></v-radio>
                          <v-radio label="3" value="3"></v-radio>
                          <v-radio label="4" value="4"></v-radio>
                          <v-radio label="5" value="5"></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <label style="color: white">*</label>
                        <v-select
                          item-color="red"

                          :items="rutina"
                          label="Rutina"
                          v-model="rutinaSelected"
                        ></v-select>
                      </v-col>
                      <v-col cols="1" sm="6" md="12">
                        <v-switch
                          v-model="switch1"
                          inset
                          label="Usuarios Implicados"
                        ></v-switch>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <MultiSelect v-if="switch1"   :filter="true" @change="AreaSeleccionada" v-model="areaSelected"  :options="area" optionLabel="text" placeholder="Area Encargada" />
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <MultiSelect v-if="switch1"  @change="DependenciaSeleccionada"  :filter="true" v-model="dependenciaSelected"  :options="dependencia" optionLabel="text" placeholder="Dependencia Encargada" />
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <MultiSelect v-if="switch1"  :filter="true" v-model="usuarioSelected" @change="prueba3"  :options="usuarios" optionLabel="text" placeholder="Usuarios" />
                        
                      </v-col>
                    </v-row>
                  </v-container>
                  
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn elevation="6" raised  @click="reiniciar"> Cerrar </v-btn>
                  <v-btn color="blue  darken-4" style="color:white;" elevation="6" raised @click="save"> Agendar </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-form>
    </template>
    <template>
        
          <v-row justify="center">
            <v-dialog v-model="dialog2" persistent max-width="100%">
              <v-card>
                <v-card-title>
                  <span class="text-h5">¿Desea Eliminar Eventos?</span>
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn elevation="6" raised  @click="dialog2 = false"> Cerrar </v-btn>
                  <v-btn color="blue  darken-4" style="color:white;" v-if="eventoActual.rutina == '1'" elevation="6" raised @click="borrar2(1)"> Borrar Todos </v-btn>
                  <v-btn color="blue  darken-4" style="color:white;" elevation="6" raised @click="borrar2(2)"> Borrar Actual </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        
    </template>
    <div id="boxLoading"></div>
    
  </v-app>
</template>

<script>
import axios from "axios";
import moment from 'moment';
var url = "/php/crud.php";
export default {
  data: () => ({
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: ["blue", "indigo", "deep-purple", "cyan", "green", "orange", "grey darken-1"],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party",
    ],
    isUpdated: false,
    dialog: false,
    items: [
      
    ],
    disable:false,
    select: [
      { text: "State 1" },
      { text: "State 2" },
      { text: "State 3" },
      { text: "State 4" },
      { text: "State 5" },
      { text: "State 6" },
      { text: "State 7" },
    ],
    Menu_Text: "A Menu",
    horaActual: "",
    fechaActual: "",
    modal2: false,
    menu2: false,
    time: null,
    time2: null,
    time3: null,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu3: false,
    jornada: [
      {
        text: "Mañana",
        value: 1,
      },
      {
        text: "Tarde",
        value: 2,
      },
      {
        text: "Indeterminado",
        value: 3,
      },
    ],
    jornadaSelected: 3,
    rutina: [
      {
        text: "Sin Repetición",
        value: 1,
      },
      {
        text: "Diario",
        value: 2,
      },
      {
        text: "Semanal",
        value: 3,
      },
      {
        text: "Mensual",
        value: 4,
      },
    ],
    rutinaSelected: 1,
    area: [
      
    ],
    dependencia: [
      
    ],
    usuarios: [
      
    ],
    actividad:[

    ],
    actividadSelected: '2',
    duracion: [
      {
        text: "1 hora",
        value: 1,
      },
      {
        text: "2 horas",
        value: 2,
      },
      {
        text: "3 horas",
        value: 3,
      },
      {
        text: "4 horas",
        value: 4,
      },
      {
        text: "5 horas",
        value: 5,
      },
      {
        text: "6 horas",
        value: 6,
      },
      {
        text: "7 horas",
        value: 7,
      },
      {
        text: "8 horas",
        value: 8,
      },
      {
        text: "9 horas",
        value: 9,
      },
      {
        text: "10 horas",
        value: 10,
      },
      {
        text: "11 horas",
        value: 11,
      },
      {
        text: "12 horas",
        value: 12,
      },
      {
        text: "13 horas",
        value: 13,
      },
      {
        text: "14 horas",
        value: 14,
      },
      {
        text: "15 horas",
        value: 15,
      },
      {
        text: "16 horas",
        value: 16,
      },
      {
        text: "17 horas",
        value: 17,
      },
      {
        text: "18 horas",
        value: 18,
      },
      {
        text: "19 horas",
        value: 19,
      },
      {
        text: "20 horas",
        value: 20,
      },
      {
        text: "21 horas",
        value: 21,
      },
      {
        text: "22 horas",
        value: 22,
      },
      {
        text: "23 horas",
        value: 23,
      },
      {
        text: "24 horas",
        value: 24,
      },
    ],
    duracionSelected: 1,
    radio: '1',
    usuarioSelected: null,
    descripcion:'',
    titulo:'',
    valid: false,
    areaSelected: null,
    dependenciaSelected: null,
    rules: {
      select: [(v) => !!v || "Item is required"],
      select2: [(v) =>  v.length>0 || "Selecciona una empresa"],
      
    },
    switch1: false,
    itemsSelected: [],
    usuarioLog: 1,
    switch2: false,
    estados: [],
    estadoSelected: null,
    estadoDisabled: false,
    dia: [
      {
        text: 'Lunes',
        value: 1
      },
      {
        text: 'Martes',
        value: 2
      },
      {
        text: 'Miercoles',
        value: 3
      },
      {
        text: 'Jueves',
        value: 4
      },
      {
        text: 'Viernes',
        value: 5
      },
      {
        text: 'Sabado',
        value: 6
      },
      {
        text: 'Domingo',
        value: 0
      },
    ],
    diaSelected: [
      {
        text: 'Lunes',
        value: 1
      },
      {
        text: 'Martes',
        value: 2
      },
      {
        text: 'Miercoles',
        value: 3
      },
      {
        text: 'Jueves',
        value: 4
      },
      {
        text: 'Viernes',
        value: 5
      }
    ],
    dialog2: false,
    eventoActual: [],
  }),
  mounted() {
    // var today = moment('2021-08-01 18:30').format("YYYY-MM-DD H:mm");
    // var fin = moment([2021,12,31,24,0]).format("YYYY-MM-DD H:mm");
    // var a = moment([2007, 0, 29]);
    // var b = moment([2007, 0, 28]);
    // console.log(fin)
    // var a = moment(['2021','08','6', '21','30'], "YYYY-MM-DD HH:mm")
    // var b = moment(['2021','08','31', '09','30'], "YYYY-MM-DD HH:mm")
    // var a = moment(['2021','08','1'], "YYYY-MM-DD")
    // var b = moment().format("YYYY");
    // b = b + '-12-31 24:00';
    // b = moment(b)
    // console.log(b.diff(a,'days'));
    // while (b.diff(a,'days') > 0) {
    //   console.log(a.format("YYYY-MM-DD"));
    //   a.add(1,'days')

      
    // }
    console.log(moment().weekday());
    this.$refs.calendar.checkChange();
    axios
      .get(url, {
        params: {
          usuario: this.usuarioLog,
        },
      })
      .then((response) => {
        this.items = response.data.empresa;
        this.itemsSelected = [this.items[0].defecto];
        this.estados = response.data.estado;
        this.actividad = response.data.actividad;
      });
  },
  methods: {
    viewDay({ date }) {
      console.log(this.events);
      console.log(this.$refs.calendar);
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      
      this.estadoSelected = event.estado;
      var fecha_evento = moment(event.start)
      var fecha_actual = moment()
      
      
      if(event.estado == 3){
        this.estadoDisabled = true;
      }else{
        this.estadoDisabled = false;
      }
      if(fecha_actual.diff(fecha_evento,'days') > 1){
        
        this.estadoDisabled = true;
      }
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange() {
      if (this.isUpdated == false) {
        
        axios
        .get(url, {
          params: {
            tarea: this.usuarioLog,
          },
        })
        .then((response) => {
          const events = [];
          console.log(response.data);
          response.data.tarea.forEach(element => {
            
            if(element.mostrar == 1){
              if(element.todo_el_dia == 1){
                var start = new Date(`${element.fecha_inicio}T00:00:00`);   
                var end = new Date(`${element.fecha_inicio}T00:00:00`);   
                events.push({
                  name: element.titulo,
                  start: start,
                  end: end,
                  color: this.colors[this.rnd(0, this.colors.length - 1)],
                  timed: false,
                  details: element.descripcion,
                  singleline: true,
                  outside: true,
                  identificador: element.identificador_tarea,
                  id: element.id,
                  estado: element.estado_tarea,
                  rutina: element.tiene_rutina,
                  lote: element.lote
                });

              }else{
                var start2 = new Date(`${element.fecha_inicio}T${element.hora_inicio}`);   
                var end2 = new Date(`${element.fecha_fin}T${element.hora_fin}`);   
                events.push({
                  name: element.titulo,
                  start: start2,
                  end: end2,
                  color: this.colors[this.rnd(0, this.colors.length - 1)],
                  timed: true,
                  details: element.descripcion,
                  singleline: true,
                  outside: true,
                  identificador: element.identificador_tarea,
                  id: element.id,
                  estado: element.estado_tarea,
                  rutina: element.tiene_rutina,
                  lote: element.lote
                });
              }
            }
            
          });
          this.events = events;
          this.isUpdated = true;
        });
      

        
        
      }
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    prueba(that) {
      this.Menu_Text = that.title;
    },
    SeleccionarEmpresa() {
      
      axios
      .get(url, {
        params: {
          area: this.itemsSelected,
        },
      })
      .then((response) => {
        this.area = response.data.area;
        // console.log(response.data);
        // this.items = response.data.empresa;
      });
      
      
    },
    AreaSeleccionada(){
      
      let identificadores = this.areaSelected.map(a=>a.identificador);
      
      axios
      .get(url, {
        params: {
          dependencia: identificadores,
        },
      })
      .then((response) => {
        this.dependencia = response.data.dependencia;
        
        // this.items = response.data.empresa;
      });
    },
    DependenciaSeleccionada(){
      
      let identificadores = this.dependenciaSelected.map(a=>a.identificador);
      console.log(identificadores);
      axios
      .get(url, {
        params: {
          usuarios: identificadores,
        },
      })
      .then((response) => {
        this.usuarios = response.data.usuarios;
        // console.log(response.data);
        
        // this.items = response.data.empresa;
      });
    },
    prueba3() {
      console.log(this.usuarioSelected)
    },
    IniciarAgenda(event) {
      var fecha_evento = moment(event.date).format("YYYY-MM-DD")
      fecha_evento = moment(fecha_evento);
      
      var fecha_actual = moment().format("YYYY-MM-DD")
      fecha_actual = moment(fecha_actual);
      
      if(fecha_actual.diff(fecha_evento,'days') <= 0){
        this.date = event.date;
        this.time2 = event.time;
        if (Number(event.time.split(":")[0]) > 12) {
          this.time =
            Number(event.time.split(":")[0] - 12) + ":" + event.time.split(":")[1] + " PM";
        } else {
          this.time = event.time + " AM ";
        }

        this.dialog = true;
        
      }
      
    },
    prueba4(event){
      console.log(event);
      console.log(this.diaSelected)
    },
    save() {
      
      const prueba = this.$refs.form.validate();
      if(prueba == true){
        var element = document.getElementById("boxLoading");
        element.classList.add("loading");
        this.dialog = false;
        /* console.log(this.rutinaSelected);
        console.log(this.date);
        console.log(this.time2);
        console.log(this.descripcion);
        console.log(this.titulo); */
        var dia = this.date.split("-");
        var hora = this.time2.split(":");
        dia = dia.concat(hora);
        var a = moment(dia, "YYYY-MM-DD HH:mm")
        var b = moment().format("YYYY");
        b = b + '-12-31 '+ this.time2 ;
        b = moment(b)
        var events = [];
        switch (this.rutinaSelected) {
          case 1:

              // var start = new Date(a.format("YYYY-MM-DD HH:mm").replace(' ','T'))
              var hora_inicio = a.format("HH:mm");
              var fecha_inicio = a.format("YYYY-MM-DD");
              // console.log(moment(start).format("HH:mm"))
              a.add(this.duracionSelected,'hours');
              // var end = new Date(a.format("YYYY-MM-DD HH:mm").replace(' ','T'))
              var hora_fin = a.format("HH:mm");
              var fecha_fin = a.format("YYYY-MM-DD");
              // console.log(moment(end).format("HH:mm"))
              

              events.push({
              hora_inicio: hora_inicio,
              hora_fin: hora_fin,
              fecha_inicio: fecha_inicio,
              fecha_fin: fecha_fin,
              })
            break;
          case 2:



            var dias = this.diaSelected.map(a=>a.value);
            
            while (b.diff(a,'days') >= 0) {
              if(dias.includes(a.weekday())){
                // var start2 = new Date(a.format("YYYY-MM-DD HH:mm").replace(' ','T'))
                var hora_inicio2 = a.format("HH:mm");
                var fecha_inicio2 = a.format("YYYY-MM-DD");

                a.add(this.duracionSelected,'hours');
                // var end2 = new Date(a.format("YYYY-MM-DD HH:mm").replace(' ','T'))
                var hora_fin2 = a.format("HH:mm");
                var fecha_fin2 = a.format("YYYY-MM-DD");
                events.push({
                hora_inicio: hora_inicio2,
                hora_fin: hora_fin2,
                fecha_inicio: fecha_inicio2,
                fecha_fin: fecha_fin2,
                })
                


                a.subtract(this.duracionSelected,'hours')
              }
              a.add(1,'days')
              


            }


            break;
          case 3:




            while (b.diff(a,'days') >= 0) {
              // var start3 = new Date(a.format("YYYY-MM-DD HH:mm").replace(' ','T'))
              var hora_inicio3 = a.format("HH:mm");
              var fecha_inicio3 = a.format("YYYY-MM-DD");
              a.add(this.duracionSelected,'hours');
              // var end3 = new Date(a.format("YYYY-MM-DD HH:mm").replace(' ','T'))

              var hora_fin3 = a.format("HH:mm");
              var fecha_fin3 = a.format("YYYY-MM-DD");
              events.push({
              hora_inicio: hora_inicio3,
              hora_fin: hora_fin3,
              fecha_inicio: fecha_inicio3,
              fecha_fin: fecha_fin3,
              })

              


              a.subtract(this.duracionSelected,'hours')
              a.add(7,'days')


            }


            break;
          case 4:



            while (b.diff(a,'days') >= 0) {
              // var start4 = new Date(a.format("YYYY-MM-DD HH:mm").replace(' ','T'))
              var hora_inicio4 = a.format("HH:mm");
              var fecha_inicio4 = a.format("YYYY-MM-DD");

              a.add(this.duracionSelected,'hours');
              // var end4 = new Date(a.format("YYYY-MM-DD HH:mm").replace(' ','T'))
              var hora_fin4 = a.format("HH:mm");
              var fecha_fin4 = a.format("YYYY-MM-DD");
              events.push({
              hora_inicio: hora_inicio4,
              hora_fin: hora_fin4,
              fecha_inicio: fecha_inicio4,
              fecha_fin: fecha_fin4,
              })

              


              a.subtract(this.duracionSelected,'hours')
              a.add(1,'month')


            }
            break;

          default:
            break;
        }
        let identificadores = this.usuarioSelected == null? [] : this.usuarioSelected.map(a=>a.value);
        identificadores.push(this.usuarioLog);
        axios.post(url,{
          insertado: true,
          eventos: events,
          titulo: this.titulo,
          descripcion: this.descripcion,
          usuarios: this.switch1 == false? [this.usuarioLog] : identificadores,
          todo_el_dia: this.switch2,
          rutina: this.rutinaSelected == 1? 0 : 1,
          usuario: this.usuarioLog,
          empresa: this.itemsSelected.join(",")

        }).then(()=>{
          this.isUpdated = false;
          this.updateRange();
          element.classList.remove("loading");
        })

      }
      
      
    },
    saveHora(){
      this.modal2 = false;
      
      this.arreglarHora(this.time2)
      
    },
    arreglarHora(hora){
      
      if (Number(hora.split(":")[0]) > 12) {
        
        this.time =
          Number(hora.split(":")[0] - 12) + ":" + hora.split(":")[1] + " PM";
      } else {
        
        this.time = hora + " AM ";
      }

    },
    cerrar(){
      this.modal2 = false;
    },
    pruebaHora(){
      console.log("ASDF");
    },
    cambioJornada(){
      if(this.jornadaSelected == 1){
        this.time = '00:00 AM'
        this.time2 = '00:00'
      }else{
        this. time = '12:00 PM'
        this.time2 = '12:00'
      }
    },
    ActualizarEstado(evento){
      console.log(evento);
      // console.log(this.selectedEvent);
      this.selectedEvent.estado = this.estadoSelected
      if(this.estadoSelected == 3){
        this.estadoDisabled = true;
      }
      axios.patch(url, {
        usuario: this.usuarioLog,
        tarea: evento.identificador,
        estado: this.estadoSelected,
        actualizar: 'estado_tarea'

      }).then(()=>{
        this.selectedOpen = false;
      })
    },
    borrar(evento){
      if(evento.rutina == "0"){
        console.log("asd");
      }else{
        console.log("ASSS");
      }
      this.dialog2 = true;
      this.eventoActual = evento;
      
    },
    borrar2(identificador){
      var element = document.getElementById("boxLoading");
      element.classList.add("loading");
      switch (identificador) {
        case 1:
          

          axios.delete(url,{
            data: {
              rutina: this.eventoActual.rutina,
              identificador: this.eventoActual.identificador,
              usuario: this.usuarioLog,
              lote: this.eventoActual.lote,
              id: this.eventoActual.id
            }
          }).then(()=>{
            this.isUpdated = false;
            this.updateRange();
            element.classList.remove("loading");
            this.selectedOpen = false;
          })
          break;
        case 2:
          axios.delete(url,{
            data: {
              rutina: this.eventoActual.rutina,
              identificador: this.eventoActual.identificador,
              usuario: this.usuarioLog,
              lote: this.eventoActual.lote,
              id: this.eventoActual.id,
              borrarUno: true
            }
          }).then(()=>{
            this.isUpdated = false;
            this.updateRange();
            element.classList.remove("loading");
            this.selectedOpen = false;
          })
          break;
      
        default:
          break;
      }
    },
    reiniciar(){
      
      this.itemsSelected = [this.items[0].defecto];
      this.switch2 = false;
      this.descripcion = '';
      this.titulo = '';
      this.switch1 = false;
      this.areaSelected = null;
      this.area = [];
      this.dependencia = [];
      this.dependenciaSelected = null;
      this.usuarioSelected = null;
      this.usuarios = [];
      this.radio = '1';
      this.rutinaSelected = 1;
      this.dialog = false;
      this.actividadSelected = '2';
      this.diaSelected = [
        {
          text: 'Lunes',
          value: 1
        },
        {
          text: 'Martes',
          value: 2
        },
        {
          text: 'Miercoles',
          value: 3
        },
        {
          text: 'Jueves',
          value: 4
        },
        {
          text: 'Viernes',
          value: 5
        }
      ]
    }
  },
};
</script>

<style>
.v-event-timed {
  max-width: 300px;
}
.v-event-timed-container {
  max-width: 90%;
}
.p-multiselect {
        width: 100%;
}
.loading {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url('https://media.giphy.com/media/xTk9ZvMnbIiIew7IpW/source.gif') 50% 50% no-repeat rgb(249, 249, 249);
    opacity: .8;
}
</style>
